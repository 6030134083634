var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.SkinComponent, {
    tag: "component",
    attrs: {
      "board": _vm.board,
      "me": _vm.me
    },
    on: {
      "save": _vm.save,
      "like": _vm.like,
      "unlike": _vm.unlike
    },
    model: {
      value: _vm.comment,
      callback: function ($$v) {
        _vm.comment = $$v;
      },
      expression: "comment"
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }